/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
@font-face {
  font-family: 'fontello';
  src: url("../fonts/fontello/font/fontello.eot?20546191");
  src: url("../fonts/fontello/font/fontello.eot?20546191#iefix") format("embedded-opentype"), url("../fonts/fontello/font/fontello.woff?20546191") format("woff"), url("../fonts/fontello/font/fontello.ttf?20546191") format("truetype"), url("../fonts/fontello/font/fontello.svg?20546191#fontello") format("svg");
  font-style: normal;
  font-weight: normal;
}

h4 {
  color: #555555;
  line-height: 1.4;
}

#primary-nav .level-1 {
  width: 20%;
}

div.brand__lockup img {
  height: 84px;
}

/*h3 a{
	&:after{
		content: "";
	    display: inline-block;
	    position: relative;
	    border-top: 5px solid transparent;
	    border-bottom: 5px solid transparent;
	    border-left: 5px solid #e67730;
	    margin-left: 5px;
	}
}*/
.hmbtns a.btn {
  width: 100%;
  background-color: #cf4520;
  color: #fff;
  min-height: 50px;
  line-height: 38px;
  margin-bottom: 20px;
}

.hmbtns a.btn:hover {
  background-color: #e7751d;
}

blockquote {
  font-size: 20px;
  line-height: 2;
}

/* =============================================================================
   ~Navigation
   ========================================================================== */
#primary-nav .level-1:last-child a:hover {
  border-bottom: none;
}

@media screen and (min-width: 768px) {
  #primary-nav .level-1 {
    padding: 1em 16px;
  }
}

@media screen and (min-width: 992px) {
  .block-menu-block {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    -ms-column-count: 3;
    -o-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    -ms-column-gap: 15px;
    -o-column-gap: 15px;
    column-gap: 15px;
    margin-bottom: 1em;
  }
  .block-menu-block li.level-1:nth-child(2) {
    display: inline-block;
  }
  .block-menu-block li.level-1:nth-child(3) {
    display: inline-block;
  }
}

.alumni {
  /*@include breakpoint($md) {
		border: 1px solid $wcm-red;
		border-radius: 8px;
		padding: .2em .8em;
	}*/
}

@media screen and (min-width: 768px) {
  .alumni {
    border: 1px solid #b31b1b;
    border-radius: 8px;
    padding: .2em .8em;
  }
}

/*.alumni:after {
		content: "\00a0";
    display: inline-block;
    font-family: "SSStandard";
    font-size: 75%;
    text-decoration: none;
    text-rendering: optimizelegibility;
    vertical-align: middle;
}*/
.alumni:hover {
  /*@include breakpoint($md) {
			background: $wcm-red;
			color: #fff !important;
		}*/
}

@media screen and (min-width: 768px) {
  .alumni:hover {
    background: #b31b1b;
    color: #fff !important;
  }
}

footer .alumni {
  /*@include breakpoint($md) {
		padding: 0;// .2em .8em;
		border: 1px solid #fff;
	}*/
}

footer .alumni:hover {
  background: none;
  color: #e7751d !important;
}

@media screen and (min-width: 768px) {
  footer .alumni {
    padding: 0;
    border: 1px solid #fff;
  }
}

@media screen and (min-width: 768px) {
  .footer-contact__contact-us a {
    padding: 20px 20px;
  }
}

@media screen and (min-width: 992px) {
  .footer-contact__contact-us a {
    padding: 20px 48px;
  }
}

@media screen and (min-width: 768px) {
  .footer-contact__social-links li {
    padding: 0 5px;
  }
}

@media screen and (min-width: 992px) {
  .footer-contact__social-links li {
    padding: 0 10px;
  }
}

/* =============================================================================
   ~Header
   ========================================================================== */
.brand__nav {
  background-color: inherit;
}

.brand__nav .navbar-nav > li > a {
  line-height: 75px;
}

.brand__logo a {
  display: block;
  background: url(../images/WCM-logo.png) no-repeat left;
  width: 210px;
  height: 50px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-size: 210px;
}

@media screen and (min-width: 768px) {
  .brand__logo a {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.global-links a {
  color: #666666;
}

.global-links a:hover {
  color: #666666;
}

.global-links > li > a:hover, .global-links > li > a:focus {
  color: #666666;
}

.search-toggle span {
  border: 2px solid #fff;
  margin-top: 20px;
}

.search-toggle span:hover {
  border: 2px solid #cf4520;
}

.search-toggle span .cta-icon-search {
  color: #cf4520;
  fill: #e7751d;
}

.search-toggle.open span {
  margin-top: 15px;
  border: 2px solid #fff;
  background: #fff;
}

.search-toggle.open span:hover .cta-icon-search {
  fill: #cf4520;
}

#tagline {
  margin: 0 auto;
  text-align: center;
  display: none;
}

@media screen and (min-width: 768px) {
  #tagline {
    background: #b31b1b;
    color: #fff;
    font-size: 2.2rem;
    letter-spacing: .65em;
    padding: .4em 0;
    display: block;
    font-weight: normal;
  }
}

@media screen and (min-width: 992px) {
  #tagline {
    background: #b31b1b;
    color: #fff;
    font-size: 2.6rem;
    letter-spacing: .75em;
    padding: .6em 0;
    display: block;
    font-weight: normal;
  }
}

#tagline a {
  color: #fff;
  text-decoration: none;
}

#tagline-mobile {
  color: #fff;
  letter-spacing: .2em;
  padding: .3em 0;
  text-align: center;
  display: block;
  font-size: 1.4rem;
}

@media screen and (min-width: 768px) {
  #tagline-mobile {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  #tagline-mobile {
    display: none;
  }
}

#donate-to-weill {
  display: none;
  float: left;
  color: #b31b1b;
}

@media screen and (min-width: 768px) {
  #donate-to-weill {
    font-size: 1.4rem;
    margin-top: .3em;
    display: none;
  }
}

@media screen and (min-width: 992px) {
  #donate-to-weill {
    font-size: 1.6rem;
    margin-top: .2em;
    display: block;
  }
}

#donate-to-weill span {
  display: block;
  margin: .2em .4em 0 0;
  float: left;
}

#donate-button-top {
  float: right;
  padding: .2em 1em .2em .8em;
  border-radius: 4px;
  text-transform: uppercase;
  background: #b31b1b;
  color: #fff;
}

@media screen and (min-width: 768px) {
  #donate-button-top {
    font-size: 1.3rem;
  }
}

@media screen and (min-width: 992px) {
  #donate-button-top {
    font-size: 1.4rem;
  }
}

#donate-button-top:hover {
  background: #fff;
  border: 1px solid #b31b1b;
  color: #b31b1b;
  cursor: pointer;
}

.title-bar {
  font-family: "1898Sans-Regular", sans-serif;
  display: block;
  font-size: 24px;
}

@media screen and (min-width: 768px) {
  .title-bar {
    font-size: 46px;
  }
}

.title-bar .site-title {
  display: block;
  padding-top: 5px;
}

@media screen and (min-width: 768px) {
  .title-bar .site-title {
    padding-top: 10px;
  }
}

#top-nav {
  margin-bottom: 20px;
}

/* External Link Highlighting */
#drawer-nav .external-link:after, .site-main .external-link:after, .footer-nav .external-link:after {
  content: '\e811';
  padding-left: 5px;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
}

/* =Home Layout
----------------------------------------------------------*/
.front .radix-sutro {
  padding-top: 60px;
}

/* =Callout Boxes
----------------------------------------------------------*/
.callout {
  margin: 40px 0;
  padding: 30px;
  border: 1px solid #dddddd;
}

.callout__title {
  font-family: "1898Sans-Bold", sans-serif;
  font-size: 40px;
  line-height: 42px;
  text-align: center;
  margin: 20px 0;
  color: #555555;
}

.callout__title a:after {
  content: '\e80d';
  color: #e7751d;
  padding-left: 10px;
  font-size: 60%;
  vertical-align: top;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  text-decoration: none;
  line-height: 1.5;
}

.callout__description {
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  color: #666666;
  text-align: center;
  margin: 0 auto 30px;
}

.callout__action {
  text-align: center;
}

.callout__action .btn--wcm {
  font-size: 16px;
  width: auto;
  display: inline-block;
}

.callout__action .btn--wcm {
  padding: 6px 42px;
}

.callout--login-logos {
  margin: 0 0 40px;
}
