@font-face {
	font-family: 'fontello';
    src: url('../fonts/fontello/font/fontello.eot?20546191');
    src: url('../fonts/fontello/font/fontello.eot?20546191#iefix') format('embedded-opentype'),
   		 url('../fonts/fontello/font/fontello.woff?20546191') format('woff'),
         url('../fonts/fontello/font/fontello.ttf?20546191') format('truetype'),
         url('../fonts/fontello/font/fontello.svg?20546191#fontello') format('svg');
      font-style: normal;
      font-weight: normal;
}

h4{
	color: $wcm-dark-gray;
	line-height: 1.4;
}

#primary-nav .level-1{
	width: 20%;
}

div.brand__lockup img{
	height: 84px;
}

/*h3 a{
	&:after{
		content: "";
	    display: inline-block;
	    position: relative;
	    border-top: 5px solid transparent;
	    border-bottom: 5px solid transparent;
	    border-left: 5px solid #e67730;
	    margin-left: 5px;
	}
}*/

.hmbtns a.btn{
	width:	100%;
	background-color: $wcm-dark-orange;
	color: #fff;
	min-height: 50px;
	line-height: 38px;
	margin-bottom: 20px;
	&:hover{
		background-color: $wcm-bright-orange;
	}
}

blockquote{
	font-size: 20px;
	line-height: 2;
}


//Header Styles

/* =============================================================================
   ~Navigation
   ========================================================================== */

#primary-nav .level-1 {
  //width: $primary-nav-width; //Customize me based on content!
    
    &:last-child a:hover {
        border-bottom: none;
    }
    
    @include breakpoint($sm) {
        padding: 1em 16px;
    }
}

.block-menu-block {
    @include breakpoint($md) {
        -moz-column-count: 3;
        -webkit-column-count: 3;
        -ms-column-count: 3;
        -o-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 15px;
        -moz-column-gap: 15px;
        -ms-column-gap: 15px;
        -o-column-gap: 15px;
        column-gap: 15px;
        margin-bottom: 1em;
        
        li.level-1:nth-child(2) {
            display: inline-block;
        }
        li.level-1:nth-child(3) {
            display: inline-block;
        }
    }
}

.alumni {
	@include breakpoint($sm) {
		border: 1px solid $wcm-red;
		border-radius: 8px;
		padding: .2em .8em;
	}
	/*@include breakpoint($md) {
		border: 1px solid $wcm-red;
		border-radius: 8px;
		padding: .2em .8em;
	}*/
}
/*.alumni:after {
		content: "\00a0";
    display: inline-block;
    font-family: "SSStandard";
    font-size: 75%;
    text-decoration: none;
    text-rendering: optimizelegibility;
    vertical-align: middle;
}*/

.alumni:hover {
		@include breakpoint($sm) {
			background: $wcm-red;
			color: #fff !important;
		}
		/*@include breakpoint($md) {
			background: $wcm-red;
			color: #fff !important;
		}*/
	}

footer .alumni {
    &:hover {
        background: none;
        color: $wcm-bright-orange !important;
    }
	@include breakpoint($sm) {
		padding: 0;// .2em .8em;
		border: 1px solid #fff;
	}
	/*@include breakpoint($md) {
		padding: 0;// .2em .8em;
		border: 1px solid #fff;
	}*/
}

.footer-contact__contact-us a {
    @include breakpoint($sm) {
        padding: 20px 20px;
    }
    @include breakpoint($md) {
        padding: 20px 48px;
    }
}

.footer-contact__social-links li {
    @include breakpoint($sm) {
        padding: 0 5px;
    }
    @include breakpoint($md) {
        padding: 0 10px;
    }
}

/* =============================================================================
   ~Header
   ========================================================================== */
.brand__nav {
    background-color: inherit;
    
    .navbar-nav>li>a {
        line-height: 75px;
    }
}

.brand__logo a {
    display: block;
    background: url(../images/WCM-logo.png) no-repeat left;
    width: 210px;
    height: 50px;
    margin-top: 5px;
    margin-bottom: 5px;
    @include breakpoint($sm) {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    background-size: 210px;
}
.global-links a {
    color: $wcm-med-gray;
    &:hover {
        color: $wcm-med-gray;
    }
}
.global-links>li>a:hover, .global-links>li>a:focus {
    color: $wcm-med-gray;
}

.search-toggle span {
    border: 2px solid #fff;
    margin-top: 20px;
    &:hover {
        border: 2px solid $wcm-dark-orange;
    }
    
    .cta-icon-search {
        color: $wcm-dark-orange;
        fill: $wcm-bright-orange;
    }
}
.search-toggle.open span {
    margin-top: 15px;
    border: 2px solid #fff;
    background: #fff;
    &:hover {
        .cta-icon-search {
            fill: $wcm-dark-orange;
        }
    }
}

#tagline {
	margin: 0 auto;
	text-align: center;
	display: none;
	@include breakpoint($sm) {
		background: $wcm-red;
		color: #fff;
		font-size: 2.2rem;
		letter-spacing: .65em;
		padding: .4em 0;
		display: block;
    font-weight: normal;
	}
	@include breakpoint($md) {
		background: $wcm-red;
		color: #fff;
		font-size: 2.6rem;
		letter-spacing: .75em;
		padding: .6em 0;
		display: block;
    font-weight: normal;
	}
}

#tagline a {
	color: #fff;
	text-decoration: none;
}

#tagline-mobile {
//	background: $secondary-color;
	color: #fff;
	letter-spacing: .2em;
	padding: .3em 0;
	text-align: center;
	display: block;
	font-size: 1.4rem;
	@include breakpoint($sm) {
		display: none;
	}
	@include breakpoint($md) {
		display: none;
	}

}

#donate-to-weill {
	display: none;
	float: left;
	color: $wcm-red;
	@include breakpoint($sm) {
		font-size: 1.4rem;
		margin-top: .3em;
		display:none;
	}
	@include breakpoint($md) {
		font-size: 1.6rem;
		margin-top: .2em;
		display:block;
	}
	& span {
		display: block;
		margin: .2em .4em 0 0;
		float: left;
	}
}

#donate-button-top {
	float: right;
	padding: .2em 1em .2em .8em;
	border-radius: 4px;
	text-transform: uppercase;
	@include breakpoint($sm) {
		font-size: 1.3rem;
	}
	@include breakpoint($md) {
		font-size: 1.4rem;
	}
	background: $wcm-red;
	color: #fff;
	&:hover {
		background: #fff;
		border: 1px solid $wcm-red;
		color: $wcm-red;
		cursor: pointer;
	}
}

.title-bar {
    font-family: $wcm-regular;
    display: block;
    font-size: 24px;
//    padding-top: 5px;
    @include breakpoint($sm) {
        font-size: 46px;
//        padding-top: 10px;
//        line-height: 1.8;
    }
    
    .site-title {
        display: block;
        padding-top: 5px;
        @include breakpoint($sm) {
            padding-top: 10px;
        }
    }
}

#top-nav{
	margin-bottom: 20px;
}

/* External Link Highlighting */


#drawer-nav .external-link:after, .site-main .external-link:after, .footer-nav .external-link:after {
    content: '\e811';
    padding-left: 5px;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-decoration: none;
}

/* =Home Layout
----------------------------------------------------------*/

.front .radix-sutro {
  padding-top: 60px;
}


/* =Callout Boxes
----------------------------------------------------------*/

.callout {
  margin: 40px 0;
  padding: 30px;
  border: 1px solid $wcm-border-gray;
}

.callout__title {
  font-family: $wcm-bold;
  font-size: 40px;
  line-height: 42px;
  text-align: center;
  margin: 20px 0;
  color: $wcm-dark-gray;
}

.callout__title a {
  &:after {
    content: '\e80d';
    color: $wcm-bright-orange;
    padding-left: 10px;
    font-size: 60%;
    vertical-align: top;
    @include fontello();
    text-decoration: none;
    line-height: 1.5;
  }
}

.callout__description {
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  color: $wcm-med-gray;
  text-align: center;
  margin: 0 auto 30px;
}

.callout__action {
  text-align: center;
}

.callout__action .btn--wcm {
  font-size: 16px;
  width: auto;
  display: inline-block;
}

.callout__action .btn--wcm {
  padding: 6px 42px;
}

.callout--login-logos {
  margin: 0 0 40px;
}
